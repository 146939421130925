class SpecificationItem {
  constructor(dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.option = dto.option;
  }

  getName() {
    return getLocalizedName(this.name);
  }

  getValue() {
    return getLocalizedName(this.option.value);
  }
}

export class Specification {
  constructor(dto) {
    this.group = dto.group;
    this.items = dto.items.map((dtoItem) => new SpecificationItem(dtoItem));
  }

  getGroupName() {
    if (null === this.group) {
      return _T("@General specifications");
    }

    return getLocalizedName(this.group);
  }
}
