import { Specification } from "~/models/specification.model";

export default definePayloadPlugin(() => {
  // Identifies the Specification class
  // And then gets the data (server-side)
  definePayloadReducer("Specification", (data) => {
    if (data instanceof Specification) {
      return {
        group: data.group,
        items: data.items,
      };
    }
  });

  // "Revive" the payload
  // Take the data -> create a new class instance
  definePayloadReviver("Specification", (data) => {
    return new Specification(data, data.parent);
  });
});
